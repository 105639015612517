<template>
  <div class="work_team_unit_wrapper cp" :class="{on: stick == '1'}">
    <!-- <img src="@/assets/label-Sticky.png" alt="" class="sticky"> -->
    <div class="img_group">
      <BasicWorkTeamAvatar :userlist="userlist" v-if="item.pictures"></BasicWorkTeamAvatar>
      <img :src="`${$avatar_url}${item.photo}`"  v-if="item.photo" alt="" class="img">
      <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img" v-for="(item,index) in userlist" :key="index"> -->
      <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
      <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
      <!-- <img src="https://tse3-mm.cn.bing.net/th/id/OIP.4tQRVuvWoRSgJ4_atKsmQQAAAA?pid=Api&rs=1" alt="" class="img"> -->
      <div class="icon"></div>
    </div>
    <div class="info_group" :class="{hasDesc: desc!=''}">
      <div class="name_group">
        <!-- 姓名 -->
        <div class="name">
          <slot name="name">&nbsp;</slot>
        </div>
        <!-- <div class="time">08:18</div> -->
      </div>
      <div class="desc_group" v-if="desc">
        <div class="desc">
          <slot name="desc">&nbsp;</slot>
        </div>
        <!-- <div class="desc">按照VIVO的KV，开始...</div> -->
        <!-- <div class="fun"></div> -->
      </div>


    </div>
  </div>
</template>
<script>
import BasicWorkTeamAvatar from '@/components/basic/workTeamAvatar.vue';
export default {
  components:{
    BasicWorkTeamAvatar,
  },
  props:{
    item:{
      type: Object,
      default: null,
    },
    userlist:{
      type: Array,
    },
    //置顶
    stick: {
      type: String,
      default: '0',
    },
    desc: {
      type: String,
      default: '',
    }
  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.work_team_unit_wrapper{
  @include u_sel_none;
  @include bbox;
  @include flex;
  @include transition;
  position: relative;
  z-index: 2;
  align-items: center;
  // width: 250px;
  width: 100%;
  padding-left: 17px;
  background-color: #fff;
  &.on,&:hover{
    background-color:#f4f4f4;
  }
  .sticky{
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    right: 0;
  }
  .img_group{
    @include flexsb;
    @include bbox;
    align-content: space-between;
    padding:1px;
    flex-wrap: wrap;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    .img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .icon{
      position: absolute;
      top:0;
      left:0;
      z-index: 3;
    }
    width:44px;
    height:44px;
    border-radius: 4px;
    // background-color: #bbb;
    $group_width: 44-3px;
  }
  .info_group{
    @include bbox;
    @include flex;
    align-items: center;
    height: 44+22px;
    padding: 11px 0;
    padding-right: 17px;
    margin-left:16px;
    width:100%;
    flex:1;
    overflow: hidden;
    // border-bottom: 1px solid #f4f4f4;
    &.hasDesc{
      @include flexsb;
      flex-direction: column;
      align-items: flex-start;
      .name_group{
        .name{
          padding-top: 3px;
        }
      }
    }
    .name_group{
      @include flexsb;
      width:100%;
      align-items: flex-start;
      .name{
        @include bbox;
        @include single_line_intercept;
        padding-right: 10px;
        padding-top:3px;
        font-size:14px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
        width: 100%;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &>span{
          @include single_line_intercept;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .time{
        flex-shrink: 0;
        font-size:12px;
        
        font-weight:400;
        color:rgba(153,153,153,1);
      }
    }
    .desc_group{
      @include flexsb;
      width:100%;
      .desc{
        flex:1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:129px;
        font-size:12px;
        
        font-weight:400;
        color:rgba(153,153,153,1);
        padding-right: 20px;
      }
      .fun{
        background: {
          image: url('~@/assets/icon-More.png');
          size: 100%;
        }
        width:2px;
        height: 10px;
      }
    }
  }
}
</style>