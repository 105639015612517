var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group_aside_contact" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("SidebarSearchGroup", {
          ref: "sidebar_search_group",
          staticClass: "sidebar_search_group",
        }),
        _c(
          "router-link",
          {
            staticClass: "apply_group cp",
            class: { on: _vm.pointer == "contact_apply" },
            attrs: { to: { name: "fun_contact_apply" } },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.pointer = "contact_apply"
                _vm.$store.commit("set_new_apply", false)
              },
            },
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.new_apply,
                  expression: "new_apply",
                },
              ],
              staticClass: "new",
            }),
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/common/apply_icon.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("申请记录")]),
          ]
        ),
        _vm.search_switch ? _c("group-aside-search") : _vm._e(),
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.search_switch,
                  expression: "!search_switch",
                },
              ],
              staticClass: "workteam_group group",
              class: { on: _vm.status_workteam },
            },
            [
              _c("div", { staticClass: "title_group" }, [
                _c("div", {
                  staticClass: "icon",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.status_workteam = !_vm.status_workteam
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.status_workteam = !_vm.status_workteam
                      },
                    },
                  },
                  [_vm._v("工作组")]
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status_workteam && _vm.workteam_list,
                      expression: "status_workteam && workteam_list",
                    },
                  ],
                  staticClass: "workteam_list",
                },
                [
                  _vm._l(_vm.workteam_list, function (item, index) {
                    return [
                      item
                        ? _c("FunWorkTeamUnit", {
                            key: `workteam${index}`,
                            class: {
                              on: _vm.pointer == `workteam${item.chatId}`,
                            },
                            attrs: { item: item, userlist: item.pictures },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.pointer = `workteam${item.chatId}`
                                _vm.gotoWorkteamDetail(item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(item.chatName)),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _vm._m(0),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.search_switch,
                  expression: "!search_switch",
                },
              ],
              staticClass: "contact_group group",
              class: { on: _vm.status_contact },
            },
            [
              _c("div", { staticClass: "title_group" }, [
                _c("div", {
                  staticClass: "icon",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.status_contact = !_vm.status_contact
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.status_contact = !_vm.status_contact
                      },
                    },
                  },
                  [_vm._v("联系人")]
                ),
              ]),
              _vm.friends_list_group && _vm.status_contact
                ? _c(
                    "div",
                    { staticClass: "contact_list" },
                    _vm._l(
                      _vm.friends_list_group,
                      function (group_item, group_index) {
                        return _c(
                          "div",
                          { key: group_index, staticClass: "firstname_group" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(group_index)),
                            ]),
                            _vm._l(group_item, function (item) {
                              return _c("FunContactUnit", {
                                key: item.friendUserId,
                                staticClass: "cp",
                                class: {
                                  on:
                                    _vm.pointer ==
                                    `friends${item.friendUserId}`,
                                },
                                attrs: { userinfo: item },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.pointer = `friends${item.friendUserId}`
                                    _vm.gotoContactDetail(item.friendUserId)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "name",
                                      fn: function () {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.friendRemark ||
                                                  item.username
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              0
                ? _c("div", { staticClass: "bottom_group" }, [
                    _c("div", { staticClass: "line" }),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.search_switch && 0,
                  expression: "!search_switch && 0",
                },
              ],
              staticClass: "maybe_group group",
              class: { on: _vm.status_maybe },
            },
            [
              _c("div", { staticClass: "title_group" }, [
                _c("div", {
                  staticClass: "icon",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.status_maybe = !_vm.status_maybe
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.status_maybe = !_vm.status_maybe
                      },
                    },
                  },
                  [_vm._v("可能认识的人")]
                ),
              ]),
              _vm._m(1),
            ]
          ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom_group" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom_group" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }