<!--
 * @Author       : Hugo
 * @Date         : 2020-06-15 08:41:09
 * @LastEditTime : 2021-03-31 15:01:21
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : /miaohang/src/components/sidebar/funContactUnit.vue
 * @^_^
--> 
<!-- 联系人 -->
<template>
  <div class="contact_unit_wrapper">
    <!-- <img src="@/assets/label-Sticky.png" alt="" class="sticky"> -->
    <div class="img_group">
      <div class="icon"></div>
      <img :src="`${$avatar_url}${userinfo.thumbnail}`" alt="" class="img">
    </div>
    <div class="info_group">
      <div class="name_group">
        <!-- 姓名 -->
        <div class="name">
          <slot name="name">&nbsp;</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    userinfo:{
      type: Object,
      default: null,
    }
  }
}
</script>
<style lang="scss" scoped>
.contact_unit_wrapper{
  @include bbox;
  @include flex;
  @include u_sel_none;
  position: relative;
  z-index: 2;
  align-items: center;
  // width: 250px;
  width: 100%;
  padding-left: 17px;
  background-color: #fff;
  &.on,&:hover{
    background-color:#f4f4f4;
  }
 
  .img_group{
    flex-shrink: 0;
    position: relative;
    .icon{
      position: absolute;
      top:0;
      left:0;
      z-index: 3;
    }
    .img{
      width:44px;
      height:44px;
      border-radius:4px;
      background-color: #f4f5f6;
      display: block;
    }
  }
  .info_group{
    @include bbox;
    @include flex;
    justify-content: center;
    height: 44+22px;
    padding: 11px 0;
    padding-right: 17px;
    flex-direction: column;
    // align-items: flex-start;
    margin-left:16px;
    overflow: hidden;
    width:100%;
    flex:1;
    // border-bottom: 1px solid #f4f4f4;
    .name_group{
      @include flexsb;
      width:100%;
      // align-items: flex-start;
      .name{
        @include single_line_intercept;
        // padding-top:3px;
        font-size:14px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
      }
      
    }
    
  }
}
</style>