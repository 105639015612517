var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "work_team_unit_wrapper cp",
      class: { on: _vm.stick == "1" },
    },
    [
      _c(
        "div",
        { staticClass: "img_group" },
        [
          _vm.item.pictures
            ? _c("BasicWorkTeamAvatar", { attrs: { userlist: _vm.userlist } })
            : _vm._e(),
          _vm.item.photo
            ? _c("img", {
                staticClass: "img",
                attrs: { src: `${_vm.$avatar_url}${_vm.item.photo}`, alt: "" },
              })
            : _vm._e(),
          _c("div", { staticClass: "icon" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info_group", class: { hasDesc: _vm.desc != "" } },
        [
          _c("div", { staticClass: "name_group" }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _vm._t("name", function () {
                  return [_vm._v(" ")]
                }),
              ],
              2
            ),
          ]),
          _vm.desc
            ? _c("div", { staticClass: "desc_group" }, [
                _c(
                  "div",
                  { staticClass: "desc" },
                  [
                    _vm._t("desc", function () {
                      return [_vm._v(" ")]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }