var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_unit_wrapper" }, [
    _c("div", { staticClass: "img_group" }, [
      _c("div", { staticClass: "icon" }),
      _c("img", {
        staticClass: "img",
        attrs: { src: `${_vm.$avatar_url}${_vm.userinfo.thumbnail}`, alt: "" },
      }),
    ]),
    _c("div", { staticClass: "info_group" }, [
      _c("div", { staticClass: "name_group" }, [
        _c(
          "div",
          { staticClass: "name" },
          [
            _vm._t("name", function () {
              return [_vm._v(" ")]
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }