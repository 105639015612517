<template>
  <div class="group_aside_contact">
    <div class="wrapper">
      <SidebarSearchGroup class="sidebar_search_group" ref="sidebar_search_group"></SidebarSearchGroup>
      
      <!-- 申请记录 -->
      <router-link
        :to="{name:'fun_contact_apply'}"
        @click.stop.native="pointer='contact_apply';$store.commit('set_new_apply', false);"
        class="apply_group cp"
        :class="{on: pointer == 'contact_apply'}"
      > 
        <div class="new" v-show="new_apply"></div>
        <img src="@/assets/images/common/apply_icon.png" alt class="icon" />
        <div class="text">申请记录</div>
      </router-link>
      <group-aside-search v-if="search_switch"></group-aside-search>
      <template v-show="!search_switch">
        <!-- 工作组 -->
        <div class="workteam_group group" v-show="!search_switch" :class="{on: status_workteam}">
          <div class="title_group">
            <div class="icon" @click.stop="status_workteam = !status_workteam"></div>
            <div class="title" @click.stop="status_workteam = !status_workteam">工作组</div>
          </div>
          <div class="workteam_list" v-show="status_workteam && workteam_list">
            <template v-for="(item,index) in workteam_list">
              <FunWorkTeamUnit
                :key="`workteam${index}`"
                :item="item"
                :userlist="item.pictures"
                @click.stop.native="pointer=`workteam${item.chatId}`;gotoWorkteamDetail(item);"
                :class="{on: pointer == `workteam${item.chatId}`}"
                v-if="item"
              >
                <template v-slot:name>
                  <span>{{item.chatName}}</span>
                </template>
              </FunWorkTeamUnit>
            </template>
            
          </div>
          <div class="bottom_group">
            <div class="line"></div>
          </div>
        </div>
        <!-- 联系人 -->
        <div
          v-show="!search_switch"
          class="contact_group group"
          :class="{on: status_contact}"
        >
          <div
            class="title_group"
          >
            <div class="icon" @click.stop="status_contact = !status_contact"></div>
            <div class="title" @click.stop="status_contact = !status_contact">联系人</div>
          </div>
          <div class="contact_list" v-if="friends_list_group && status_contact">
            <div
              class="firstname_group"
              v-for="(group_item,group_index) in friends_list_group"
              :key="group_index"
            >
              <div class="title">{{group_index}}</div>
              <FunContactUnit
                v-for="(item) in group_item"
                :key="item.friendUserId"
                :userinfo="item"
                :class="{on:pointer == `friends${item.friendUserId}`}"
                class="cp"
                @click.stop.native="pointer=`friends${item.friendUserId}`;gotoContactDetail(item.friendUserId);"
              >
                <template v-slot:name>
                  <span>{{item.friendRemark || item.username}}</span>
                </template>
              </FunContactUnit>
            </div>
          </div>
          <div class="bottom_group" v-if="0">
            <div class="line"></div>
          </div>
        </div>
        <!-- 可能认识的人 -->
        <div class="maybe_group group" v-show="!search_switch && 0" :class="{on: status_maybe}">
          <div class="title_group">
            <div class="icon" @click.stop="status_maybe = !status_maybe"></div>
            <div class="title" @click.stop="status_maybe = !status_maybe">可能认识的人</div>
          </div>
          <div class="bottom_group">
            <div class="line"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SidebarSearchGroup from "@/components/sidebar/searchGroup.vue";
import FunContactUnit from "@/components/sidebar/funContactUnit.vue";
import FunWorkTeamUnit from "@/components/sidebar/funWorkTeamUnit.vue";
import GroupAsideSearch from "@/components/Group/Aside/Search.vue";
export default {
  components: {
    SidebarSearchGroup,
    FunContactUnit,
    FunWorkTeamUnit,
    GroupAsideSearch,
  },
  data() {
    return {
      pointer: "", //当前选中
      // friends_list_group: null, // 好友列表分组
      // workteam_list: null,
      /**
       * 打开状态
       */
      status_contact: true,
      status_workteam: false,
      status_maybe: false
    };
  },
  computed: {
    /**
     * 好友分组列表
     */
    friends_list_group(){
      return this.$store.state.friends_list_group;
    },
    /**
     * 工作组列表
     */
    workteam_list(){
      return this.$store.state.workteam_list;
    },
    /**
     * 新申请
     */
    new_apply(){
      return this.$store.state.new_apply;
    },
     // 搜索
    search_switch(){
      return this.$store.state.common.search_switch;
    },
  },
  watch: {
    /**
     * 监听好友列表
     */
    friends_list_group: {
      handler: async function(newval){
        if(newval == null){
          this.set_friends_list();
        }
      },
      deep: true,
    },
    /**
     * 监听工作组
     */
    workteam_list: {
      handler: async function(newval){
        if(newval == null){
          this.set_workteam_list();
        }
      },
      deep: true,
    },
    new_apply: function(){},
    search_switch: function(){},
  },
  methods: {
    // 搜索高亮
    highlightKeywords(str, keywords) {
      return this.$tools.highlightKeywords(str, keywords);
    },
    /**
     * @description: 获取好友列表(state)
     * @param {type} 
     * @return: 
     */
    async set_friends_list(){
      if(this.friends_list_group == null){
        await this.$store.dispatch('get_friends_list');
      }
    },
   
    /**
     * 跳转到联系人详情
     * @param {Number|String} id 联系人id
     */
    gotoContactDetail(id) {
      this.$router.push({ name: "fun_contact_detail", params: { id: this.$tools.btoa(id) } });
    },
    /**
     * @description: 获取工作组列表
     * @param {type} 
     * @return: 
     */
    async set_workteam_list(){
      if(this.workteam_list == null){
        this.$store.dispatch('get_workteam_list');
        // this.$store.commit('set_wrokteam_list', null);
      }
    },
   
    gotoWorkteamDetail(item) {
      this.$store.commit('set_contact_data', item); // 设置联系人信息
      this.$router.push({ name: "fun_contact_workteam_detail", params:{id: this.$tools.btoa(item.chatId)} });
      // 工作组名
      this.$store.commit('set_workteam_detail_name', item.chatName || '未命名');
      this.$store.dispatch('close_all_popover'); // 关闭所有弹出框
    },
    async init(){
      this.set_friends_list();
      this.set_workteam_list();
      this.$store.dispatch('common_clear_search');
    }
  },
  async mounted() {
    this.init();
  }
};
</script>
<style lang="scss" scoped>
.group_aside_contact {
  width: 100%;
  height: 100%;
  .wrapper {
    @include hidescrollbar;
    position: relative;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    .sidebar_search_group {
      position: sticky;
      top: 0;
      z-index: 3;
    }
    .apply_group {
      @include transition;
      margin-top: 9px;
      margin-bottom: 9px;
      @include bbox;
      @include flex;
      width: 100%;
      padding: 11px 0;
      padding-left: 17px;
      background-color: #fff;
      position: relative;
      &:hover,
      &.on {
        background-color: $background_hover_color;
      }
      .new{
        position: absolute;
        width: 10px;
        height: 10px;
        top: 8px;
        left: 14px;
        border-radius: 50%;
        background-color: $red_dot;

      }
      .icon {
        width: 44px;
        height: 44px;
        border-radius: 4px;
      }
      .text {
        padding-left: 20px;
        font-size: 14px;
        
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
    }
    .group {
      &.on {
        .title_group {
          .icon {
            // width: 10px;
            // height: 8px;
            background-size: 10px 8px;
            background-image: url(~@/assets/images/common/workteam_extend.png);
          }
        }
      }
      .title_group {
        @include u_sel_none;
        @include bbox;
        @include flex;
        height: 54px;
        padding-left: 17px;
        .icon {
          // @include transition;
          @include background(8px 10px);
          width: 10px;
          height: 10px;
          // background-size: 100%;
          background-image: url(~@/assets/images/common/workteam_packup.png);
          cursor: pointer;
        }
        .title {
          cursor: pointer;
          font-size: 14px;
          
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          padding-left: 10px;
        }
      }
      .firstname_group {
        .title {
          padding-left: 17px;
          font-size: 14px;
          
          font-weight: 400;
          color: rgba(170, 170, 170, 1);
          text-transform: uppercase;
        }
      }
      .bottom_group {
        @include bbox;
        padding-left: 17px;
        .line {
          width: 100%;
          height: 2px;
          background-color: $border_color;
        }
      }
    }
  }
}
</style>